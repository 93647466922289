<template>
  <div id="nav">
    <router-link :to="{name:'Home'}">Home</router-link> |
    <router-link :to="{name:'ASN Generator'}">ASN Generator</router-link> |
    <router-link :to="{name:'PO Generator'}">PO Generator</router-link> |
    <router-link :to="{name:'Master Data Generator'}">Master Data Generator</router-link> |
    <router-link :to="{name:'About'}">About</router-link>
  </div>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
