import purchaseOrderGenApi from "../../api/purchase-order-gen-api";

const state = () => ({
    status: {
        loading: false,
        message: "",
        data: {}
    },
    createdPurchaseOrderCDMs: new Map(),
    vendorCdms: [],
    vendorItemCdms: [],
})

const getters = {
    getStatus: state => { return state.status },
    getcreatedPurchaseOrderCDMs: state => { return state.createdPurchaseOrderCDMs },
    getVendorCDMs: state => { return state.vendorCdms },
    getVendorItemCDMs: state => { return state.vendorItemCdms },
}

const actions = {
    createNewPurchaseOrderCDM({commit}, {purchaseOrderData, generate}) {
        updateStatus(commit, true, "Creating Purchase Order CDM....");
        purchaseOrderGenApi.createPurchaseOrder(purchaseOrderData, generate).then(res => {
            addCreatedPurchaseOrderCDM(commit, res.data);
            updateStatus(commit, false, "Finished Creating Purchase Order CDM", res);
        });
    },
    loadVendorCDMs({commit}) {
        updateStatus(commit, true, "Loading Vendor CDMs...");
        purchaseOrderGenApi.getVendors().then(res => {
            updateVendorCDMs(commit, res.data);
            updateStatus(commit, false, "Finished loading Vendor CDMs", res);
        })
    },
    loadVendorItemCDMs({commit}, vendorCode) {
        updateStatus(commit, true, "Loading Vendor Item CDMs...");
        purchaseOrderGenApi.getVendorItems(vendorCode).then(res => {
            updateVendorItemCDMs(commit, res.data);
            updateStatus(commit, false, "Finished loading Vendor Items CDMs", res);
        })
    }
}

const mutations = {
    addCreatedPurchaseOrderCDM(state, purchaseOrderCDM) {
        const key = purchaseOrderCDM.PurchaseOrderId;
        state.createdPurchaseOrderCDMs.set(key, purchaseOrderCDM);
    },
    setStatus(state, status) {
        state.status = status
    },
    setVendorCDMs(state, vendorCdms) {
        state.vendorCdms = vendorCdms
    },
    setVendorItemCDMs(state, vendorItemCdms) {
        state.vendorItemCdms = vendorItemCdms
    }
}

const addCreatedPurchaseOrderCDM = (commit, purchaseOrder) => {
    commit('addCreatedPurchaseOrderCDM', purchaseOrder)
}

const updateStatus = (commit, loading, message, data) => {
    commit('setStatus', {loading, message, data})
}

const updateVendorCDMs = (commit, vendorCdms) => {
    commit('setVendorCDMs', vendorCdms)
}

const updateVendorItemCDMs = (commit, vendorItemCdms) => {
    commit('setVendorItemCDMs', vendorItemCdms)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}