import config from "../config/config";

export default {
    createPurchaseOrder(purchaseOrderData, generate=false) {
        return postPurchaseOrder(purchaseOrderData, generate);
    },    
    getVendors() {
        return getVendors();
    },
    getVendorItems(vendorCode) {
        return getVendorItems(vendorCode);
    }
}

function getEndpoint() {
    const endpoint = config.api.baseUrl
    if (!endpoint)
        throw Error("Invalid API Endpoint!")
    return endpoint
}

function getVendors() {
    return new Promise((resolve, reject) => {
        fetch(`${getEndpoint()}/vendors?type=cdm`)
            .then(res => {
                res.json().then(res => {
                    resolve(res)
                })
            })
            .catch(err => {
                reject(err)
            })          
    })
}

function getVendorItems(vendorCode) {
    return new Promise((resolve, reject) => {
        fetch(`${getEndpoint()}/vendors/${vendorCode}/items`)
            .then(res => {
                res.json().then(res => {
                    resolve(res)
                })
            })
            .catch(err => {
                reject(err)
            })          
    })
}

function postPurchaseOrder(purchaseOrderData, generate) {
    let fetchOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: purchaseOrderData }) 
    }
    return new Promise((resolve, reject) => {
        fetch(`${getEndpoint()}/purchase-orders?generate=${generate ? 'yes' : 'no'}`, fetchOptions)
            .then(res => {
                res.json().then(res => {
                    resolve(res)
                })
            })
            .catch(err => {
                reject(err)
            })          
    })
}
