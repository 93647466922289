import { createRouter, createWebHistory } from 'vue-router'
import config from '../config/config';
import Root from '../views/Root.vue'

export const RouteNames = {
    Home: "Home",
    About: "About",
    AsnGen: "ASN Generator",
    POGen: "PO Generator",
    MasterDataGen: "Master Data Generator",
}

const routes = [
  {
    path: config.app.baseUrl,
    name: 'Root',
    component: Root,
    children: [
      {
        path: '',
        name: RouteNames.Home,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      },
      {
        path: 'asngen',
        name: RouteNames.AsnGen,
        component: () => import('../views/AsnGenerator.vue')
      },
      {
        path: 'pogen',
        name: RouteNames.POGen,
        component: () => import('../views/PurchaseOrderGenerator.vue')
      },
      {
        path: 'mastergen',
        name: RouteNames.MasterDataGen,
        component: () => import('../views/MasterDataGenerator.vue')
      },
      {
        path: 'about',
        name: RouteNames.About,
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      }
    ]
  },
]

const router = createRouter({
  base: config.app.baseUrl,
  history: createWebHistory(),  
  routes
})

export default router
