export const cdmTypes = {
    VENDOR: {
        type: "VENDOR",
        name: "Vendor"
    },
    CUSTOMER: {
        type: "CUSTOMER",
        name: "Customer"
    },
    MANUFACTURER: {
        type: "MANUFACTURER",
        name: "Manufacturer"
    },
    ITEM: {
        type: "ITEM",
        name: "Item"
    }
}

export function Item() {
    
    this.clear = () => {
        this.manufacturerItem = {
            manufacturerCode: "",
            manufacturerItemCode: "",
            expiryDateProvided: false,
            lotProvided: false,
            serialNumberProvided: false,
            description: [
                { shortDescription: "" }
            ],
            systemManagedType: "TECSYS",
        },
        this.item = {
            itemCode: "",
            ancillaryItemInformation: "",
            chargeDescriptorCode: "",
            clinicalImplant: false,
            clinicallyReusable: false,
            clinicallySterile: false,
            clinicalTissue: false,
            decimalAllowed: false,
            defaultConsignmentBillingType: "",
            defaultConsignmentEnabled: false,
            expiryDateDefaulted: false,
            expiryLeadTime: 0,
            hazardousMaterialClass: "",
            hazardousMaterialEnabled: false,
            hazardousMaterialIdentificationNumber: "",
            hazardousMaterialShippingName: "",
            latexCode: "",
            licenseClassificationCode: "",
            licenseVerificationRequired: false,
            lotEntryRequired: false,
            nationalDrugCode: "",
            packageCode: "",
            packageCodesProvided: false,
            patientBillable: false,
            serialNumberRequired: false,
            shelfLife: 0,
            stocked: false,
            subjectToRegulation: false,
            tagTracked: false,
            universalProductCode: "",
            unspscClassification: "",
            useByDateAndTimeAutoAssignmentType: "",
            useByDays: 0,
            useByHours: 0,
            manufacturerCode: "",
            manufacturerItemCode: "",
            itemDescription: [
                { shortDescription: "" }
            ],
            packagingUom: [
                { 
                    gtin: "",
                    multiplier: 1,
                    sequence: 1,
                    uomCode: "",
                    default: false,
                    stocking: false,
                    description: [
                        { shortDescription: "" }
                    ],
                }
            ],
            transactionUom: [
                { 
                    gtin: "",
                    multiplier: 1,
                    sellingDefault: false,
                    default: false,
                    stocking: false,
                    uomCode: "",
                    description: [
                        { shortDescription: "" }
                    ],
                }
            ],
            warehouse: [
                {
                    abcCountClass: "",
                    consolidatedPackageCode: "",
                    lotEntryRequired: false,
                    serialNumberRequired: false,
                    supplySiteCode: "",
                    supplySiteItem: false,
                    tagTracked: false,
                    warehouseCode: "",
                    warehouseType: "",
                }
            ],
            systemManagedType: "TECSYS",
        },
        this.vendorItem = {
            vendorCode: "",
            vendorItemCode: "",
            supplySiteItem: false,
            universalProductCode: "",
            itemCode: "",
            manufacturerCode: "",
            manufacturerItemCode: "",
            description: [
                { shortDescription: "" }
            ],
            vendorUom: [
                { 
                    gtin: "",
                    multiplier: 1,
                    purchasingDefault: false,
                    default: false,
                    stocking: false,
                    uomCode: "",
                    vendorUomCode: "",
                    description: [
                        { shortDescription: "" }
                    ],
                }
            ],
            systemManagedType: "TECSYS",
        }
    }

    this.init = () => {
        this.clear()
    }

    this.init()
}

export function Vendor() {
    
    this.clear = () => {
        this.vendorCode = "",
        this.contact = "",
        this.licenseVerificationRequired = false,
        this.supplySiteProvider = false,
        this.description = [
            { shortDescription: "" }
        ],
        this.location = [
            { 
                address: {
                    address1: "",
                    address2: undefined,
                    address3: undefined,
                    city: "",
                    county: "",
                    stateOrProvince: "",
                    country: "",
                    zipOrPostalCode: ""
                },
                email: "",
                faxNumber: "",
                globalLocationNumber: [{ globalLocationNumber: "", default: true}],
                phoneNumber1: "",
                phoneNumber2: "",
                vendorLocationCode: ""
            }
        ],
        this.organization = [
            {
                organizationCode: "",
                trustedForReceiving: false
            }
        ],
        this.systemManagedType = "TECSYS"        
    }

    this.init = () => {
        this.clear()
    }

    this.init()
}

export function Customer() {
    
    this.clear = () => {
        this.customerCode = "",
        this.barcodeTracked = false,
        this.externalReference = "",
        this.faxNumber = "",
        this.licenseVerificationRequired = false,
        this.phoneNumber1 = "",
        this.phoneNumber2 = "",
        this.receivingSiteLocation = false,
        this.rfidTracked = false,
        this.shipToCustomerCode = "",
        this.viaCode = "",
        this.supplyLocation = false,
        this.warehouseCode = "",
        this.description = [
            { shortDescription: "" }
        ],
        this.address = {
            address1: "",
            address2: undefined,
            address3: undefined,
            city: "",
            county: "",
            stateOrProvince: "",
            country: "",
            zipOrPostalCode: ""
        },
        this.organization = [
            {
                organizationCode: "",
                trustedForReceiving: false
            }
        ],
        this.globalLocationNumber = [
            {
                default: true,
                globalLocationNumber: ""
            }
        ],
        this.systemManagedType = "TECSYS"     
    }

    this.init = () => {
        this.clear()
    }

    this.init()
}

export function Manufacturer() {
    
    this.clear = () => {
        this.manufacturerCode = "",
        this.labelerIdentificationCode = "",
        this.description = [
            { shortDescription: "" }
        ],
        this.systemManagedType = "TECSYS"       
    }

    this.init = () => {
        this.clear()
    }

    this.init()
}